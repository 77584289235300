import React, { useRef, useState, useEffect, useCallback } from 'react';
import Webcam from 'react-webcam';
import { Box, Button } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import axios from 'axios';
import { CustomMessage } from '../Const/Spinner';
import { enqueueSnackbar } from 'notistack';

interface CameraComponentProps {
  logoSrc: string;
  logoWidth?: number;
  logoHeight?: number;
  onCapture?: (file: File, latitude: number | null, longitude: number | null, timestamp: string | null) => void;
  buttonText?: string;
  facingMode?: 'user' | 'environment'; // 'user' for front camera, 'environment' for back camera
}

const CameraComponent: React.FC<CameraComponentProps> = ({
  logoSrc,
  logoWidth = 100,
  logoHeight = 50,
  onCapture,
  buttonText = "Capture photo",
  facingMode = 'user' 
}) => {
  const webcamRef = useRef<Webcam>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imgSrc, setImgSrc] = useState<string | null>(null);
  const [latitude, setLatitude] = useState<number | null>(null);
  const [longitude, setLongitude] = useState<number | null>(null);
  const [timestamp, setTimestamp] = useState<string | null>(null);
  const apiKey = "AIzaSyCVPNOs751x8Gacat1RqBJXSMJPLTyi1NM"; // Replace with your API key

  const loadImage = (src: string) => {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = () => resolve(img);
      img.onerror = reject;
    });
  };

   const fetchLocationName = async () => {
    if (latitude && longitude) {
      try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
        );

        if (response.data.status === "OK") {
          const address = response.data.results[0].formatted_address;
          return address;
          // setLocationName(address);
        } else {
          console.error("Error in fetching location: ", response.data.status);
          // setLocationName(null);
          return null;
        }
      } catch (error) {
        console.error("Error in fetching location: ", error);
        // setLocationName(null);
        return null;
      }
    }
  };

  const capture = useCallback(async () => {
    try{
      const imageSrc = webcamRef.current?.getScreenshot();
      const newTimestamp = dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
      setTimestamp(newTimestamp);
  
      const location_name = await fetchLocationName(); // Ensure location name is fetched before capturing image
  
      if (imageSrc && canvasRef.current) {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        try {
          const [img, logo] = await Promise.all([
            loadImage(imageSrc),
            loadImage(logoSrc)
          ]);
  
          // Draw the captured image
          canvas.width = img.width;
          canvas.height = img.height;
          ctx?.drawImage(img, 0, 0);
  
          // Draw the logo
          ctx?.drawImage(logo, canvas.width - logoWidth - 10, 10, logoWidth, logoHeight);
  
          // Draw the timestamp, latitude, and longitude
          // Determine the font size based on screen width
          const fontSize = window.innerWidth < 600 ? 14 : 20;
          ctx!.font = `${fontSize}px Arial`;
          ctx!.fillStyle = 'white';
          // ctx!.font = "12px Arial";
          ctx!.fillText(newTimestamp, 10, canvas.height - 62);
           
              // Draw the location name (or lat/long if location name isn't available)
              const locationText = location_name || `Lat: ${latitude}, Long: ${longitude}`;
              const maxWidth = canvas.width - 20; // Leave some padding on both sides
  
              // Split text into multiple lines if it's too long
              const lines = [];
              let line = '';
              for (let i = 0; i < locationText.length; i++) {
                  const testLine = line + locationText[i];
                  const metrics = ctx!.measureText(testLine);
                  const testWidth = metrics.width;
                  if (testWidth > maxWidth && i > 0) {
                      lines.push(line);
                      line = locationText[i];
                  } else {
                      line = testLine;
                  }
              }
              lines.push(line);
  
              // Draw each line
              lines.forEach((text, index) => {
                  ctx!.fillText(text, 10, canvas.height - 10 - (20 * (lines.length - index - 1)));
              });
  
          // Get the data URL of the final image
          const finalImgSrc = canvas.toDataURL('image/jpeg');
          setImgSrc(finalImgSrc);
        } catch (error:any) {
          console.error("Error loading images: ", error);
          CustomMessage(error?.message,'warning',enqueueSnackbar)
        }
      }else{
        CustomMessage("Webcam not available or image capture failed.",'info',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }
  }, [logoSrc, logoWidth, logoHeight, latitude, longitude]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
      });
    }
  }, []);

  const handleRetake = () => {
    setImgSrc(null);
  };

  const handleUpload = () => {
    if (imgSrc) {
      // Convert base64 image to file
      const byteString = atob(imgSrc.split(',')[1]);
      const mimeString = imgSrc.split(',')[0].split(':')[1].split(';')[0];
      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([ab], { type: mimeString });
      const file = new File([blob], "captured_image.jpg", { type: mimeString });

      if (onCapture) {
        onCapture(file, latitude, longitude,timestamp);
      }
    }
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
      {imgSrc ? (
        <Box>
          <Box
            component="img"
            src={imgSrc}
            alt="Captured"
            sx={{ width: '100%', maxWidth: '100%', borderRadius: 8 }}
          />
          <Box mt={2} display="flex" justifyContent="center">
            <Button variant="contained" color="primary" onClick={handleRetake} sx={{ mr: 1 }}>
              Retake
            </Button>
            <Button variant="contained" color="secondary" onClick={handleUpload}>
              Upload
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={{
          width: '100%',
          height: { xs: 'calc(80vh - 80px)', md: 'calc(60vh - 60px)' }, // Adjust height based on screen size
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode }}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover' // Ensures the webcam feed covers the whole area
            }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={capture}
            sx={{ mt: 2 }}
          >
            {buttonText}
          </Button>
        </Box>
      )}
      <canvas ref={canvasRef} style={{ display: 'none' }} />
    </Box>
  );
};

export default CameraComponent;
