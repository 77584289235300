import { CompletedLoadingPackType, DriverTatPath, LashingLoadingPackType, TarpaulinLoadingPackType, UserChangePwdInterface, VehiclePathType, defaultLoadingPackingDataType, userDataInterface, userLoginInterface } from "./type";

export const userLoginDefault: userLoginInterface = {
    username:"",
    password:""
}

export const userDataDefault: userDataInterface ={
    user: {
        id: 0,
        username: "",
        name: "",
        password: "",
        roleMapping:[]
    },
    transaction: {
        id: 0,
        userId: 0,
        date: null,
        checkinImgId: 0,
        checkinFilePath: null,
        checkinLatitude: null,
        checkinLongitude: null,
        checkoutLatitude: null,
        checkoutLongitude: null,
        checkoutImgId: 0,
        checkoutFilePath: null
    }
}

export const defaultDataLoadingPacking: defaultLoadingPackingDataType ={
    completed: [] as CompletedLoadingPackType[],
    id:0,
    lashing: [] as LashingLoadingPackType[],
    tarpaulin: [] as TarpaulinLoadingPackType[],
    status:'',
    vehAttachmentId:0,
    vehNum:'',
    vehFiles:[] as VehiclePathType[],
    driverTat:[] as DriverTatPath[],
    vehType:"vehicle",
    createdBy:0,
    updatedBy:0,
    materialName:"",
    workersName:"",
    loadingName:"",
    truckType:"",
    weight:'',
    vehicleTat:"",
    remainingTat:"",
    completedTat:"",
    assignedTat:"",
    handoverTat:"",
    approvedTat:"",
    plant:"",
    grossWeight:"",
    noOfBundles:""
}