import React, { useState, useEffect } from "react";
import { Box, Checkbox, FormControlLabel, Button, Typography } from "@mui/material";

// Props for the checklist component
interface ChecklistItem {
  id: number;
  label: string;
  checked: boolean;
}

interface ChecklistProps {
  items: ChecklistItem[];
  onAllChecked: () => void; // Callback when all items are checked
}

const LoadingChecklist: React.FC<ChecklistProps> = ({ items, onAllChecked }) => {
  const [checklist, setChecklist] = useState<ChecklistItem[]>(items);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    const allChecked = checklist.every(item => item.checked);
    setSelectAll(allChecked); // Keep the Select All checkbox in sync
    // if (allChecked) {
    //   onAllChecked();
    // }
  }, [checklist,
    //  onAllChecked
    ]);

  const handleCheckboxChange = (id: number) => {
    const updatedChecklist = checklist.map(item =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setChecklist(updatedChecklist);
  };

   // Handle Select All checkbox
  const handleSelectAllChange = () => {
    const newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    const updatedChecklist = checklist.map(item => ({ ...item, checked: newSelectAll }));
    setChecklist(updatedChecklist);
  };

  return (
    <Box p={2} sx={{ width: "100%", maxWidth: 1200, mx: "auto" }}>
      <Typography variant="h6" gutterBottom>
        Checklist
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={selectAll}
            indeterminate={!selectAll && checklist.some(item => item.checked)}
            onChange={handleSelectAllChange}
          />
        }
        label="Select All"
        sx={{ mb: 2 }}
      />
      <Box 
        sx={{ display: "grid", gridTemplateColumns: { xs: "1fr", md: "1fr 1fr" }, gap: 2 }}
    >
        {checklist.map((item) => (
          <FormControlLabel
            key={item.id}
            control={
              <Checkbox
                checked={item.checked}
                onChange={() => handleCheckboxChange(item.id)}
                name={`checkbox-${item.id}`}
              />
            }
            label={
                <Typography 
                    sx={{ 
                        whiteSpace: "unset", wordBreak:"break-word",
                        overflowWrap: "break-word", // Ensures long words break within container
                        // maxWidth: "300px", // Set a limit on the width for word wrapping
                        display: "inline-block", // Ensure it respects the maxWidth
                    }}
                >
                    {item.label}
                </Typography>
            }
          />
        ))}
      </Box>
      <Button
        variant="contained"
        color="primary"
        disabled={!checklist.every(item => item.checked)}
        onClick={() => onAllChecked()}
        sx={{ mt: 3 }}
      >
        Proceed
      </Button>
    </Box>
  );
};

export default LoadingChecklist;
