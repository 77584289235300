import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import { Provider } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SnackbarProvider } from 'notistack';
import { store, persistor } from './Redux/store/ReduxStore';
import { PersistGate } from 'redux-persist/integration/react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { getAnalytics } from "firebase/analytics";
import { FILE_PATH_LOCATION } from './Const/endpoints';

export const vapidKey = "BFlvTy6w1rFoJUCPB0kbrsF3ag33fIzccsbfmPFJaxJ-5v4cBmlhwZFGipHoa1_GtGXitX_ZWkuPtNT4_QxYWgo";

const firebaseConfig = {
  apiKey: "AIzaSyDPD-9vVkKI5ZlSl48Eb7UNCcRPJesv4FU",
  authDomain: "krishca-inventory-notification.firebaseapp.com",
  projectId: "krishca-inventory-notification",
  storageBucket: "krishca-inventory-notification.appspot.com",
  messagingSenderId: "755951358232",
  appId: "1:755951358232:web:2966c5e349aaa033e48e6d",
  measurementId: "G-WPWC9KSJV1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// Initialize Firebase Cloud Messaging
export const messaging = getMessaging(app);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// Request permission for notifications and register the service worker
export const requestForToken = async () => {
  try {
    const token = await getToken(
      messaging,
      { 
        vapidKey: vapidKey, 
        serviceWorkerRegistration: await navigator.serviceWorker.register(FILE_PATH_LOCATION) 
      }
    );
    if (token) {
      console.log('FCM token:', token);
      return token;
    } else {
      console.log('No registration token available. Request permission to generate one.');
    }
  } catch (err) {
    console.log('Error retrieving FCM token:', err);
  }
};

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider 
          maxSnack={3} anchorOrigin={{vertical:'bottom',horizontal:"center"}}
          autoHideDuration={2000}
          preventDuplicate
          style={{ width: '80%' }}
        >
          <App />
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
