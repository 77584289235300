import { ColDef, ITooltipParams } from "ag-grid-community";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../Redux/store/ReduxStore";
import { dateFormatter, TimeStampValue } from "../../Const/const";
import { setLoading } from "../../Redux/CommonSlice";
import { getListStockReportData } from "../../Const";
import { CustomMessage, Loader } from "../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { defaultLoadingPackingList, StockReportListType } from "../../Const/type";
import { AgGridReact } from "ag-grid-react";
import { Box, Button, Divider, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip} from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import XLSX from "xlsx-color";
import saveAs from 'file-saver';
import { Nullable } from "primereact/ts-helpers";
import { Calendar } from 'primereact/calendar';
import dayjs from "dayjs";
import { Refresh } from "@mui/icons-material";

const StockReport: React.FC = () => {

    const dispatch = useDispatch();
    const loading = useSelector((state: RootState) => state.common.data.loading);
    const user = useSelector((state: RootState) => state.auth.user.id);

    const [stockReport, setStockReportList] = useState<StockReportListType[]>([]);
    const [dates, setDates] = useState<Nullable<(Date | null)[]>>([new Date(), new Date()]);
    const [filterType, setFilterType] = useState<string>('All');
    const gridApiRef = useRef<any>(null);
    const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const EXCEL_EXTENSION = ".xlsx";
    const fileName = "Stock_report";
    const [selectedPlant,setSelectedPlant] = useState("--Select--");
    const [listPlant,setListPlant] = useState([
        "--Select--",
        "All","Plant1","Plant2"]);

    const handlePlantChange = (event: any) => {
        setSelectedPlant(event.target.value);
    };

    const handleFilterTypeChange = (event: SelectChangeEvent<string>) => {
        setFilterType(event.target.value);
    };

    const toolTipValueGetter = (params: ITooltipParams) => params.value == null || params.value === '' ? '- Missing -' : params.value;

    const columnDefs: ColDef[] = [
        {
            headerName: "Item",
            field: "itemName",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "itemName",
        },
        {
            headerName: "Created at",
            field: "createdTime",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "createdTime",
            valueFormatter: (params) => dayjs(params?.data?.createdTime).format("MMM D, YYYY, HH:mm")
        },
        {
            headerName: "Quantity",
            field: "qty",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "qty",
        },
        {
            headerName: "New Value",
            field: "newVal",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "newVal",
        },
        {
            headerName: "Old Value",
            field: "oldVal",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "oldVal",
        },
        {
            headerName: "Action",
            field: "action",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "action",
        },
        {
            headerName: "Comments",
            field: "comments",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "comments",
        },
        {
            headerName: "Plant",
            field: "plant",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "plant",
        },
        {
            headerName: "Created By",
            field: "createdByName",
            filter: "agSetColumnFilter",
            sortable: true,
            editable: false,
            tooltipValueGetter: toolTipValueGetter,
            tooltipField: "createdByName",
        },
    ];

    const defaultColDef = useMemo<ColDef>(() => {
        return {
            minWidth: 50,
            filter: true,
        };
    }, []);

    const getRowId = useMemo(() => {
        return (params: any) => params?.data?.id;
    }, []);

    useEffect(() => {
        if (gridApiRef.current) {
            gridApiRef.current.api.sizeColumnsToFit();
        }
    }, []);

    const getListOfLoadingPackingData = async () => {
        if (!dates || dates.length !== 2 || !dates[0] || !dates[1]) {
            CustomMessage("Please select a valid date range.", 'error', enqueueSnackbar);
            return;
        }

        if(selectedPlant === "" || selectedPlant === "--Select--"){
            CustomMessage("select the plant",'error',enqueueSnackbar);
            return;
        }

        const [fromDate, endDate] = dates.map((date) => dayjs(date).startOf('day').toDate());

        // Check if fromDate is after endDate
        if (fromDate > endDate) {
            CustomMessage("The 'From' date cannot be after the 'To' date.", 'error', enqueueSnackbar);
            return;
        }
        // Format dates as YYYY-MM-DD strings
        const formattedFromDate = dayjs(fromDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');
        dispatch(setLoading(true));
        try {
            const response = await getListStockReportData(filterType,formattedFromDate, formattedEndDate,selectedPlant);
            if (response?.data?.length > 0) {
                setStockReportList(response?.data);
            } else {
                CustomMessage('No data', 'error', enqueueSnackbar);
                setStockReportList([]);
            }
        } catch (error: any) {
            CustomMessage(error?.message, 'error', enqueueSnackbar);
        } finally {
            dispatch(setLoading(false));
        }
    }

   const exportToExcel = () => {
        // Create a new workbook
       const wb = XLSX.utils.book_new(); 

       const headerStyle = { fill: { fgColor: { rgb: "fff2cc" } } };
        const borderStyle = {
            border: {
                top: { style: 'thin', color: { rgb: "000000" } },
                bottom: { style: 'thin', color: { rgb: "000000" } },
                left: { style: 'thin', color: { rgb: "000000" } },
                right: { style: 'thin', color: { rgb: "000000" } },
            }
        };  
        // Prepare Excel data
        const questionData: any[] = [];

        questionData.push([
            { v:'S.No', s:{ ...headerStyle, ...borderStyle }},
            { v:'Item', s:{ ...headerStyle, ...borderStyle }},
            { v:'Date', s:{ ...headerStyle, ...borderStyle }}, 
            { v:"Quantity", s:{ ...headerStyle, ...borderStyle }}, 
            { v:"Old Value", s:{ ...headerStyle, ...borderStyle }}, 
            { v:"New Value", s:{ ...headerStyle, ...borderStyle }}, 
            { v:"Action", s:{ ...headerStyle, ...borderStyle }}, 
            { v:"Comments", s:{ ...headerStyle, ...borderStyle }}, 
            { v:"Plant", s:{ ...headerStyle, ...borderStyle }},
            { v:'created By', s:{ ...headerStyle, ...borderStyle }}
        ]);

        stockReport?.forEach((data,i) => {
            questionData.push([
                { v: i+1, s: borderStyle },                
                { v: data?.itemName, s: borderStyle },                
                { v: dayjs(data?.createdTime).format("MMM D, YYYY, HH:mm") ?? "", s: borderStyle },                
                { v: data?.qty, s: borderStyle },                
                { v: data?.oldVal, s: borderStyle },                
                { v: data?.newVal, s: borderStyle },                
                { v: data?.action, s: borderStyle },                
                { v: data?.comments, s: borderStyle },     
                { v: data?.plant, s: borderStyle },           
                { v: data?.createdByName, s: borderStyle },                
            ]);
        });
        
        // Calculate column widths
        const colWidths = questionData.reduce((acc: number[], row) => {
            row.forEach((cell: any, colIndex: number) => {
                const cellWidth = (cell ? String(cell).length * 1.2 : 10);
                acc[colIndex] = acc[colIndex] ? Math.max(acc[colIndex], cellWidth) : cellWidth;
            });
            return acc;
        }, []);
        
        // Convert data to worksheet
        const wsQuestion = XLSX.utils.aoa_to_sheet(questionData);
        // Set column widths
        wsQuestion['!cols'] = colWidths.map((width: number) => ({ width }));
        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, wsQuestion, "stock");

        // Generate Excel file
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        // Convert buffer to Blob
        const blob = new Blob([excelBuffer], { type: EXCEL_TYPE });

        // Download Blob as Excel file
        saveAs(blob, `Stock_report_${TimeStampValue()}` + EXCEL_EXTENSION);
    };

    return (
        <>
            {loading && <Loader />}
            <div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" readOnlyInput hideOnRangeSelection showIcon/>
                        <FormControl className="plant-dropdown" fullWidth>
                            <Select
                            labelId="plantSelect-label"
                            id="plantSelect"
                            value={selectedPlant}
                            label="Select Plant"
                            onChange={handlePlantChange}
                            >
                            {listPlant.map((plant) => (
                                <MenuItem key={plant} value={plant}>{plant}</MenuItem>
                            ))}
                            </Select>
                        </FormControl>
                    <FormControl>
                        <Select
                        labelId="filter-type-label"
                        value={filterType}
                        onChange={handleFilterTypeChange}
                        >
                        <MenuItem value="All">All</MenuItem>
                        <MenuItem value="Inward">Inward</MenuItem>
                        <MenuItem value="Outward">Outward</MenuItem>
                        </Select>
                    </FormControl>
                    <Box sx={{ display: 'flex', flexWrap:"wrap", justifyContent:"space-between", gap: 2 }}>
                        <Button variant="contained" onClick={getListOfLoadingPackingData}>
                            Get Data
                        </Button>
                        <Tooltip title="Refresh Data" arrow>
                            <IconButton onClick={getListOfLoadingPackingData}>
                                <Refresh />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    </Box>
                </LocalizationProvider>
            </div>
            {
                stockReport?.length>0 && (
                    <div className=" mt-5" >
                        <Button variant="outlined" onClick={exportToExcel} >Export</Button>
                        <div className="ag-theme-alpine ag-grid-container">
                            <AgGridReact
                                className="ag-theme-alpine"
                                defaultColDef={defaultColDef}
                                rowData={stockReport}
                                columnDefs={columnDefs}
                                onGridReady={(params) => (gridApiRef.current = params)}
                                domLayout="autoHeight"
                                pagination={true}
                                paginationPageSize={5}
                                paginationPageSizeSelector={[5, 10, 25]}
                                getRowId={getRowId}
                                suppressMenuHide={true}
                            />
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default StockReport;