// src/components/Sidebar.tsx
import { Box, Drawer, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import MenuClose from '@mui/icons-material/MenuOpen';
import { hasRole } from './RenderSidebar';
import { useSelector } from 'react-redux';
import { RootState } from '../Redux/store/ReduxStore';

type SidebarProps = {
  open: boolean;
  toggleSidebar: () => void;
};

const Sidebar: React.FC<SidebarProps> = ({ open, toggleSidebar }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state:RootState)=>state.auth.user);
    const [activeItem, setActiveItem] = React.useState("/");

    console.info(user.roleMapping,'user rolse');

    useEffect(() => {
      const currentLocation = window.location.pathname || "/";
      setActiveItem(currentLocation);
    }, [location]);

    useEffect(() => {
      if (location.pathname === '/app/checkin' && hasRole(["Admin", "KRISHCA_VEDANTA_SV"], user.roleMapping)) {
        navigate('checkin');
      }
      else if(location.pathname === '/app/checkin' && hasRole(["Admin", "KRISHCA_VEDANTA_SV"], user.roleMapping)){
        navigate('stock');
      }
      else if(location.pathname === '/app/checkin' && hasRole(["Admin","VEDANTA_OSL","KRISHCA_VEDANTA_SV",'VEDANTA_ADMIN'], user.roleMapping)){
        navigate("loading-packing");
      }
      else if(location.pathname === '/app/checkin' && hasRole(["Admin","KRISHCA_VEDANTA_SV","VEDANTA_OSL",'VEDANTA_ADMIN'], user.roleMapping)){
        navigate("reports");
      }
      else if(location.pathname === '/app/checkin' && hasRole(["Admin","KRISHCA_VEDANTA_SV"], user.roleMapping)){
        navigate("masters");
      }
      else{
        navigate('change-pwd');
      }
    }, [user.roleMapping]);
  return (
    <Drawer
        open={open}
        onClose={toggleSidebar} 
        sx={{
            width: 240, // Adjust the width as needed
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column', // Makes the sidebar a flex column
        }}
    >
        <IconButton edge="end" aria-label="menu" onClick={toggleSidebar}>
          <MenuClose />
        </IconButton>
        <List sx={{ flexGrow: 1 }}>
        {
          hasRole(["Admin", "KRISHCA_VEDANTA_SV"],user.roleMapping) && (
            <ListItem 
              button onClick={()=>{
                navigate("/app/checkin");
                toggleSidebar();
              }
              } 
              className={`${activeItem === '/app/checkin' ? 'menu-active' : ""}`}
            >
                <ListItemText primary={'Attendance'}/>
            </ListItem>
          )
        }
        {
          hasRole(["Admin","KRISHCA_VEDANTA_SV"],user.roleMapping) && (
            <ListItem 
              button onClick={()=>{
                navigate("/app/stock");
                toggleSidebar();
              }} 
              className={`${activeItem === '/app/stock' ? 'menu-active' : ""}`}
            >
                <ListItemText primary={'Stock'}/>
            </ListItem>
          )
        }
        {
          hasRole(["Admin","VEDANTA_OSL","KRISHCA_VEDANTA_SV","VEDANTA_ADMIN"],user.roleMapping) && (
            <ListItem 
              button onClick={()=>{
                navigate("/app/loading-packing");
                toggleSidebar();
              }} 
              className={`${activeItem === '/app/loading-packing' ? 'menu-active' : ""}`}
            >
                <ListItemText primary={'Loading / Packing'}/>
            </ListItem>
          )
        }
        {
          hasRole(["Admin","KRISHCA_VEDANTA_SV","VEDANTA_OSL", 'VEDANTA_ADMIN'],user.roleMapping) && (
            <ListItem 
              button onClick={()=>{
                navigate("/app/reports");
                toggleSidebar();
              }} 
              className={`${activeItem === '/app/reports' ? 'menu-active' : ""}`}
            >
                <ListItemText primary={'Reports'}/>
            </ListItem>
          )
        }
        {
          hasRole(["Admin","KRISHCA_VEDANTA_SV",],user.roleMapping) && (
            <ListItem 
              button onClick={()=>{
                navigate("/app/masters");
                toggleSidebar();
              }} 
              className={`${activeItem === '/app/masters' ? 'menu-active' : ""}`}
            >
                <ListItemText primary={'Masters'}/>
            </ListItem>
          )
        }
        <ListItem 
          button onClick={()=>{
            navigate("/app/change-pwd");
            toggleSidebar();
          }} 
          className={`${activeItem === '/app/change-pwd' ? 'menu-active' : ""}`}
        >
            <ListItemText primary={'Change password'}/>
        </ListItem>

      </List>
      <Box sx={{ p: 2, borderTop: '1px solid #ddd'}}>
        <Typography variant="body2" color="ActiveBorder">
          {user.name} {/* Display user's name */}
        </Typography>
        <Typography variant='caption' color="CaptionText">
          {user.username}
        </Typography>
      </Box>
    </Drawer>
  );
};

export default Sidebar;
