import React, { useEffect, useMemo, useRef, useState } from "react";
import { Grid, TextField, Button, Typography, Paper, FormControl, InputLabel, Select, MenuItem, Checkbox, ListItemText, SelectChangeEvent, InputAdornment, IconButton } from "@mui/material";
import { userMasterDefault } from "../../Const/const";
import { useDispatch, useSelector } from "react-redux";
import { CustomMessage } from "../../Const/Spinner";
import { enqueueSnackbar } from "notistack";
import { setLoading } from "../../Redux/CommonSlice";
import { getByUserId, getListOfRolesData, getUserList, insertUserMaster } from "../../Const";
import { RootState } from "../../Redux/store/ReduxStore";
import { AgGridReact } from "ag-grid-react";
import { ColDef, ITooltipParams } from "ag-grid-community";
import EditIcon from '@mui/icons-material/Edit';
import { UserListMaster } from "../../Const/type";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const UserMaster: React.FC = () => {
  const dispatch = useDispatch();
  const userDetails = useSelector((state:RootState)=>state.auth.user);
  const [userData, setUserData] = useState(userMasterDefault);
  const [roles,setRoles] = useState<{id:number;name:string}[]>([]);
  const [rowData, setRowData] = useState<UserListMaster[]>([]);

  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  // Prevent default action when clicking the visibility icon
  const handleMouseDownPassword = (event: { preventDefault: () => void; }) => {
    event.preventDefault();
  };

  const gridApiRef = useRef<any>(null);

  const toolTipValueGetter = (params: ITooltipParams) => params.value == null || params.value === '' ? '- Missing -' : params.value;
    const columnDefs: ColDef[] = [
      {
        headerName: "UserName",
        field: "username",
        filter: "agSetColumnFilter",
        sortable: true,
        editable: false,
        flex: 1,
        tooltipValueGetter: toolTipValueGetter,
        tooltipField: "username",
      },
      {
        headerName: "Name",
        field: "name",
        filter: "agSetColumnFilter",
        sortable: true,
        editable: false,
        flex: 0.5,
        tooltipValueGetter: toolTipValueGetter,
        tooltipField: "name",
      },
      {
        headerName: "Roles",
        field: "roles",
        filter: "agSetColumnFilter",
        sortable: true,
        editable: false,
        flex: 0.5,
        tooltipValueGetter: toolTipValueGetter,
        tooltipField: "roles",
        cellRenderer: (params: any) => params.value.join(", "),
        valueFormatter: (params:any) => params.value.join(", ")
      },
      {
          field: 'actions',
          headerName: 'Actions',
          sortable: false,
          flex:0.2,
          cellRenderer: (params:any) => (
          <div>
              <span
                style={{ cursor: 'pointer', color: 'rgba(255, 138, 0, 1)' }}
                onClick={() => handleGetUser(params?.data?.id)}
              >
                  <EditIcon />
              </span>
          </div>
          ),
      }
    ]

    const handleGetUser = async (userId:number) => {
      dispatch(setLoading(true));
      try{
        const response = await getByUserId(userId);
        if(response?.status.code === 200){
          setUserData(response?.data);
        }else{
          CustomMessage(response?.status?.message,'error',enqueueSnackbar);
        }
      }catch(error:any){
        CustomMessage(error?.message,'error',enqueueSnackbar);
      }finally{
        dispatch(setLoading(false));
      }
    }
    useEffect(()=>{
      if(rowData?.length === 0){
        getUserListData();
      }
    },[rowData]);

    const getUserListData = async () => {
      dispatch(setLoading(true));
      try{
        const response = await getUserList();
        if(response?.data.length>0 && response.status.code === 200){
          setRowData(response?.data);
        }else{
          CustomMessage(response.status.message,'error',enqueueSnackbar);
        }
      }catch(error:any){
        CustomMessage(error?.message,'error',enqueueSnackbar);
      }finally{
        dispatch(setLoading(false));
      }
    }
    useEffect(() => {
      if (gridApiRef.current) {
          gridApiRef.current.api.sizeColumnsToFit();
      }
    }, []);
    const getRowId = useMemo(() => {
        return (params : any) => params?.data?.id;
    }, []);

  useEffect(()=>{
    if(roles.length===0){
      getRolesData();
    }
  },[roles]);

  const getRolesData = async () => {
    try{
      const response = await getListOfRolesData();
        let { data, status } = response;
        if (status.code === 200 && data?.length > 0) {
            setRoles(data);
        } else {
            CustomMessage(status.message, 'error', enqueueSnackbar);
        }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }
  }

  // Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  // Handle multiple role selection change
  const handleRoleChange = (e: SelectChangeEvent<number[]>) => {
    const updatedUserData = { ...userData };
    updatedUserData.roleIds = e.target.value as number[]; // Explicitly cast to number[]
    setUserData(updatedUserData);
  };

  const handleClear = () => {
    setUserData({
      createdBy:0,
      id:0,
      name:'',
      password:'',
      roleIds:[],
      updatedBy:0,
      username:''
    });
  }

  const handleSubmit = async (e:any) => {
    e?.preventDefault();
    const data = {...userData};
    if(data.id === 0){
      data.createdBy = userDetails.id;
    }else{
      data.updatedBy = userDetails.id;
    }
    dispatch(setLoading(true));
    try{
      const response = await insertUserMaster(data);
      if(response.status.code === 200){
          CustomMessage(response.status.message,'success',enqueueSnackbar);
          handleClear();
          getUserListData();
      }else{
          CustomMessage(response.status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  };

  const defaultColDef = useMemo<ColDef>(() => {
      return {
        minWidth: 100,
        filter: true,
      };
    }, []);

  return (
    <>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Name"
                name="name"
                variant="outlined"
                value={userData.name}
                onChange={handleInputChange}
                sx={{ marginBottom: "1.5rem" }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="email"
                fullWidth
                label="Username"
                name="username"
                variant="outlined"
                value={userData.username}
                onChange={handleInputChange}
                sx={{ marginBottom: "1.5rem" }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Password"
                name="password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                value={userData.password}
                onChange={handleInputChange}
                sx={{ marginBottom: "1.5rem" }}
                required
                InputProps={{
                // Add the visibility toggle button
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth sx={{ marginBottom: "1.5rem" }}>
              <InputLabel>Roles</InputLabel>
              <Select
                multiple
                value={userData.roleIds}
                onChange={handleRoleChange}
                label="Roles"
                renderValue={(selected) =>
                  (selected as number[])
                    .map((roleId) => roles.find((role) => role.id === roleId)?.name)
                    .join(", ")
                }
                required
              >
                {roles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    <Checkbox checked={userData.roleIds.indexOf(role.id) > -1} />
                    <ListItemText primary={role.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            sx={{ marginTop: "1rem" }}
            type="submit"
          >
            Submit
          </Button>
        </form>

        <div className="ag-theme-alpine" style={{ height: 400, width: "100%" }}>
          <AgGridReact
            className="ag-theme-alpine"
            rowData={rowData}
            columnDefs={columnDefs}
            onGridReady={(params) => (gridApiRef.current = params)}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
            pagination={true}
            paginationPageSize={25}
            getRowId={getRowId}
          />  
        </div>
      </>
  );
};

export default UserMaster;