import React, { useEffect, useState } from 'react';
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput
}
from 'mdb-react-ui-kit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { userLoginDefault } from '../Const/StateVariable';
import { CustomMessage } from '../Const/Spinner';
import { enqueueSnackbar } from 'notistack';
import { useDispatch } from 'react-redux';
import { setLoading } from '../Redux/CommonSlice';
import { addNotifyToken, signinUserData } from '../Const';
import { setAuthentication } from '../Redux/Reducer/AuthReducer';
import { useNavigate } from 'react-router-dom';
import { getToken, onMessage } from 'firebase/messaging';
import {messaging} from "../index"
import './Login.css'
import logo1 from "../assets/company_logo.png";
import logog2 from "../assets/Vedanta-logo.png";
import krishcaLogo from "../assets/krishca.jpg";
import vedantaLogo from "../assets/vedanta.jpg";

const Login:React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [passwordType,setPasswordType] = useState("password");
  const [userLogin,setUserLogin] = useState(userLoginDefault);
  const vapidKey = "BFlvTy6w1rFoJUCPB0kbrsF3ag33fIzccsbfmPFJaxJ-5v4cBmlhwZFGipHoa1_GtGXitX_ZWkuPtNT4_QxYWgo";

  const passwordToggle = () => {
    if(passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password')
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e?.target;
    const updatedUserLogin = {...userLogin};
      updatedUserLogin[name] = value;
    setUserLogin(updatedUserLogin);
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    if(userLogin?.username === "" || userLogin?.password === ""){
      CustomMessage("Enter the required field",'error',enqueueSnackbar);
      return;
    }
    dispatch(setLoading(true));
    try{
      const response = await signinUserData(userLogin);
      const {data,status} = response;
      if(status.code === 200 && data !== null){
        dispatch(setAuthentication(data));
        // CustomMessage(status.message,'success',enqueueSnackbar);
        navigate("/app/checkin");
        requestFCMToken(data.user.id);
      }else{
        CustomMessage(status.message,'error',enqueueSnackbar);
      }
    }catch(error:any){
      CustomMessage(error?.message,'error',enqueueSnackbar);
    }finally{
      dispatch(setLoading(false));
    }
  }

   const requestNotificationPermission = async () => {
    try {
      const permission = await Notification.requestPermission();
      return permission === 'granted';
    } catch (error: any) {
      console.error('Error requesting notification permission:', error);
      // CustomMessage(`Error requesting notification permission: ${error?.message}`, 'error', enqueueSnackbar);
      return false;
    }
  };

  const requestFCMToken = async (userId: number) => {
    const permissionGranted = await requestNotificationPermission();
    if (!permissionGranted) return;

    try {
      const token = await getToken(messaging, { vapidKey: vapidKey });
      if (token) {
        await addNotifyToken({ userId, token });
      } else {
        // CustomMessage('No registration token available.', 'error', enqueueSnackbar);
      }
    } catch (error: any) {
      console.error('Error retrieving FCM token:', error);
      // CustomMessage(`Error retrieving FCM token: ${error?.message}`, 'error', enqueueSnackbar);
    }
  };

  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log('Message received: ', payload);
    });
  }, []);

  return (

  <MDBContainer fluid className="login-container">
  <MDBRow>
    {/* Left Section - Branding and Content */}
    <MDBCol md="6" className="login-image-section">
       <div className="logo-container">
        <img src={krishcaLogo} className="login-logo1" loading="lazy"/>
        <img src={vedantaLogo} alt="Logo 2" className="login-logo2" loading='lazy' />
       </div>
      <h1 className="app-tagline">WELCOME TO KRISHCA LOGISTICS APP</h1>
      <p className="app-description">
        KRISHCA LOGISTICS, developed by Krishca Strapping Solutions, designed to enhance operations for Vedanta Aluminum Ltd. This app offers real-time tracking, standardize operations, and streamlined logistics management, ensuring efficient and secure transport.
      </p>
    </MDBCol>

    {/* Right Section - Login Form */}
    <MDBCol md="6" className="login-form-section">
      <MDBCard className="login-card">
        <MDBCardBody style={{color:'white'}}>
          <h2 className="fw-bold mb-2 text-center text-uppercase">Login</h2>
          <p className="text-center mb-4" style={{color:'white'}}>
            Enter your credentials to access your account.
          </p>
          <form onSubmit={handleSubmit}>
            <MDBInput
              wrapperClass="mb-4 input-wrapper"
              label="Email Address"
              id="emailInput"
              type="email"
              size="lg"
              required
              value={userLogin.username}
              name="username"
              onChange={handleChange}
            />
            <div className="mb-4 position-relative input-wrapper">
              <MDBInput
                label="Password"
                id="passwordInput"
                type={passwordType}
                size="lg"
                required
                value={userLogin.password}
                name="password"
                onChange={handleChange}
              />
              <span
                onClick={passwordToggle}
                className="password-toggle-icon"
              >
                {passwordType === 'password' ? (
                  <VisibilityOffIcon />
                ) : (
                  <VisibilityIcon />
                )}
              </span>
            </div>
            <p className="small text-end">
              <a href="/forgot-pwd" className="text-warning">
                Forgot password?
              </a>
            </p>
            <MDBBtn className="w-100 mb-3" size="lg" type="submit">
              Login
            </MDBBtn>
          </form>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>
</MDBContainer>

  );
}

export default Login;