import { enqueueSnackbar } from "notistack";
import { CustomMessage } from "./Spinner";
import dayjs from "dayjs";
import { UserMasterInterface } from "./type";

export const decodeBase64 = (base64String: string | null) => {
  try {
    // Decode the base64 string
    // const decoded = atob(base64String ?? '');

    // Create a data URL
    const dataURL = `data:image/jpeg;base64,${base64String}`;
    return dataURL;
  } catch (error) {
    CustomMessage('Error decoding base64 image','error',enqueueSnackbar);
    console.error('Error decoding base64 string:', error);
    return "";
  }
};

export const dateFormatter = (responseDate:string) => {
  const date = new Date(responseDate);
    // Options for formatting
  const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
  // Format the date
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
}


export const stringToTime = (dateTime:string | null) => {
  if(dateTime === null){
    return "N/A";
  }
  const time = dayjs(dateTime).format("HH:mm:ss");
  return time;
}

export const TimeStampValue = ():string=> {
  return dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss");
}

export const roleRender = (roles:string[]) => {
  const data =["KRISHCA_VEDANTA_SV"].some(role => roles.includes(role));
  // console.log(data);
  return data
}


export const loadingChecklistItems = [
    { id: 1, label: "Ensure to wear safety helmet, safety jacket and safety shoe before entering to the plant. These are mandatory for  truck drivers.", checked: false },
    { id: 2, label: "Always park in declared parking zone (maintain 3 meter distance from other vehicle) Do not to keep the vehicle in ON condition also remove ignition key while getting down from the vehicle. Use wheel chock and hand break.", checked: false },
    { id: 3, label: "Always use pedestrian walkway inside FG yard. Do not use mobile phone while walking inside the yard. Don't use earphone inside truck also inside yard.", checked: false },
    { id: 4, label: "Good quality water proof tarpaulin is mandatory for wire rod truck. All drivers must have 2 number of water proof tarpulin with them so that material should not get wet and should reach safety to this customer.", checked: false },
    { id: 5, label: "Do not climb on the truck above 1.8 meter height.", checked: false },
    { id: 6, label: "Do not enter inside barricading zone.", checked: false },
    { id: 7, label: "Do not get down from the truck untill or unless if there is an urgency and get down from the vehicle with supervisor's permission only.", checked: false },
    { id: 8, label: "Reversing is not allowed inside the yard. If there is any urgency it has to be done with help of helper and with proper barrication.", checked: false },
  ];


  export const userMasterDefault:UserMasterInterface = {
    id:0,
    name:"",
    password:"",
    username:'',
    createdBy:0,
    updatedBy:0,
    roleIds:[]as number[]
  }